'use client';
import { useEffect, useRef } from 'react';
import { useLogger } from 'next-axiom';

export function usePageViewTime(pageName) {
  const log = useLogger();
  const startTimeRef = useRef(null);

  useEffect(() => {
    startTimeRef.current = Date.now();
    return () => {
      const endTime = new Date();
      const timeSpent = (new Date(endTime) - new Date(startTimeRef.current)) / 1000;
      console.log(timeSpent);

      log.info('Page view time', {
        timestamp_start: new Date(startTimeRef.current).toString(),
        timestamp_end: new Date(endTime).toString(),
        time_spent_seconds: timeSpent.toFixed(2),
        path: pageName,
      });
    };
  }, [log, pageName]);
}
